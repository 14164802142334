<template>
  <div class="absolute inset-0 grid grid-rows-[1fr_auto]">
    <div class="overflow-hidden overflow-y-scroll pb-8">
      <u-button
        icon="i-ph-x"
        class="fixed right-4 top-1 z-50"
        variant="ghost"
        size="xl"
        :disabled="isCheckingOut"
        @click="app.layout.isSlideoverCartOpen = false"
      />
      <div
        v-if="
          auth.userCartShowObjects.size === 0 && auth.userCartExtras.size === 0
        "
        class="my-8 mt-[46vh] flex w-full flex-col items-center gap-y-4"
      >
        <p>Your cart is currently empty.</p>
        <p>
          <u-button
            variant="link"
            to="/"
            class="p-0"
            @click="app.layout.isSlideoverCartOpen = false"
          >
            Browse events.
          </u-button>
        </p>
      </div>
      <div
        v-else
        class="grid items-center gap-y-4"
      >
        <template v-if="auth.userCartShowObjects.size">
          <h5 class="sticky top-0 z-10 w-full bg-white p-2 dark:bg-gray-900">
            Entries
          </h5>
          <div
            v-for="[itemId, item] in auth.userCartShowObjects"
            :key="itemId"
            :class="{
              'grid': true,
              'grid-cols-[1fr_40px]': true,
              'border border-gray-300 dark:border-gray-700': true,
              'rounded': true,
              'overflow-hidden': true,
              'mx-2': true,
            }"
          >
            <div
              :class="{
                'grid': true,
                'w-full': true,
                'gap-y-4': true,
                'p-2': true,
                'overflow-hidden': true,
                'overflow-x-auto': true,
              }"
            >
              <div>
                <u-button
                  variant="link"
                  :to="`/event/${useShortId(item.id)}/enter`"
                  :title="item.name"
                  class="w-fit p-0"
                >
                  {{ item.name }}
                </u-button>
                <p>
                  <strong>{{ item.show.name }}</strong>
                </p>
                <p>
                  {{ useMoneyFormat(item.show.computed_price) }}
                </p>
              </div>
              <div>
                <p>
                  <strong>{{ item.item.division }}</strong>
                </p>
                <app-detail
                  v-for="(value, key) in item.item.exhibitor_info_submitted"
                  :key="key"
                  :label="key"
                  :value="value"
                />
                <app-detail
                  v-for="[key, value] in Object.entries(
                    item.item.info_submitted,
                  ).sort(([keyA], [keyB]) => useEntryDisplaySort(keyA, keyB))"
                  :key="key"
                  :label="key"
                  :value="value"
                />
              </div>
              <p
                v-if="app.data.time.isAfter(item.registration_end_at)"
                class="text-sm text-red-500"
              >
                Registration closed on
                {{
                  useTime(item.registration_end_at).format(
                    useFancyDateFormat(),
                  )
                }}.
              </p>
              <p
                v-if="item.metadata.is_draft"
                class="text-sm text-red-500"
              >
                This event is not yet accepting entries.
              </p>
              <p
                v-if="
                  item.show.is_entries_full || item.is_entries_per_specie_full
                "
                class="text-sm text-red-500"
              >
                This show is full.
              </p>
              <p
                v-if="item.show.is_entries_per_exhibitor_full"
                class="text-sm text-red-500"
              >
                Allowed entries exceeded:
                {{
                  item.show.show_entries_current
                    + item.show.show_entries_requested
                }}
                / {{ item.show.max_entries_per_exhibitor }}
              </p>
              <p
                v-if="item.is_entries_per_specie_per_exhibitor_full"
                class="text-sm text-red-500"
              >
                Allowed
                {{ item.specie }}
                entries exceeded:
                {{ item.show.total_entries }}
                /
                {{ item.metadata.max_entries_per_specie_per_exhibitor }}
              </p>
              <div v-if="item.subuser">
                <p class="text-sm text-gray-500 dark:text-gray-400">
                  <strong>
                    {{ item.subuser.name_first }}
                    {{ item.subuser.name_last }}
                  </strong>
                </p>
              </div>
            </div>
            <u-dropdown
              class="mr-4 mt-4 h-fit justify-self-end"
              :items="[
                [
                  ...(Object.keys(item.item.exhibitor_info_submitted ?? {})
                    .length
                    || Object.keys(item.item.info_submitted ?? {}).length
                    ? [
                      {
                        label: 'Edit',
                        icon: 'i-ph-note-pencil',
                        click: () => editObject(item),
                      },
                    ]
                    : []),
                  {
                    label: 'Delete',
                    icon: 'i-ph-trash',
                    click: () => removeItem(item.item.id),
                  },
                ],
              ]"
              :popper="{ placement: 'bottom-end' }"
              :disabled="isCheckingOut || app.state.isLoading"
            >
              <u-button
                square
                icon="i-ph-dots-three-circle-vertical"
                class="p-0"
                variant="ghost"
                color="white"
                :disabled="isCheckingOut || app.state.isLoading"
              />
            </u-dropdown>
          </div>
        </template>
        <template v-if="auth.userCartExtras.size">
          <h5 class="sticky top-0 z-10 w-full bg-white p-2 dark:bg-gray-900">
            Extras
          </h5>
          <div
            v-for="[itemId, item] in auth.userCartExtras"
            :key="itemId"
            :class="{
              'grid': true,
              'grid-cols-[1fr_40px]': true,
              'border border-gray-300 dark:border-gray-700': true,
              'rounded': true,
              'overflow-hidden': true,
              'mx-2': true,
            }"
          >
            <div
              :class="{
                'grid': true,
                'w-full': true,
                'gap-y-4': true,
                'p-2': true,
                'overflow-hidden': true,
                'overflow-x-auto': true,
              }"
            >
              <div
                v-if="
                  typeof item.extra.photo_url === 'string'
                    && item.extra.photo_url.length
                "
                :class="{
                  'max-h-24': true,
                  'max-w-24': true,
                  'min-h-24': true,
                  'min-w-24': true,
                  'flex': true,
                  'items-center': true,
                  'overflow-hidden': true,
                }"
              >
                <nuxt-img
                  :src="item.extra.photo_url"
                  :alt="`Image for ${item.extra.name}`"
                  class="w-full rounded-sm object-cover"
                  draggable="false"
                />
              </div>
              <div>
                <u-button
                  variant="link"
                  :to="`/event/${useShortId(item.id)}/enter`"
                  :title="item.name"
                  class="w-fit p-0"
                  :disabled="isCheckingOut || app.state.isLoading"
                >
                  {{ item.name }}
                </u-button>
                <p>
                  <strong> {{ item.extra.name }} </strong>
                </p>
                <p>{{ useMoneyFormat(item.extra.computed_price) }}</p>
              </div>
              <div>
                <p class="m-0 text-sm">
                  Qty: {{ item.item.quantity_requested }}
                </p>
                <p
                  v-for="(value, variantName) in item.item.variants_requested"
                  :key="variantName"
                  class="m-0 text-sm"
                >
                  <strong>{{ variantName }}</strong>: {{ value }}
                </p>
                <p
                  v-for="(value, question) in item.item.info_submitted"
                  :key="question"
                  class="m-0 text-sm"
                >
                  <strong>{{ question }}</strong>:
                  {{
                    typeof value === "boolean" ? (value ? "Yes" : "No") : value
                  }}
                </p>
              </div>
              <p
                v-if="
                  totalQuantityRequested[item.item.id.split('.')[0]]
                    > item.quantity_remaining
                "
                class="text-sm text-red-500"
              >
                Quantity requested (
                {{ totalQuantityRequested[item.item.id.split(".")[0]] }}
                ) exceeds quantity available ({{ item.quantity_remaining }}).
                Please remove excess extras from your cart to continue your
                entry.
              </p>
              <p
                v-if="app.data.time.isAfter(item.extra.not_available_at)"
                class="text-sm text-red-500"
              >
                Extra is no longer available. Please remove from your cart to
                continue your entry.
              </p>
              <div
                v-if="item.subuser"
                class="text-sm text-gray-500 dark:text-gray-400"
              >
                <p>
                  <strong>
                    {{ (item.subuser as PortalSubuser).name_first }}
                    {{ (item.subuser as PortalSubuser).name_last }}
                  </strong>
                </p>
              </div>
            </div>
            <u-dropdown
              class="mr-4 mt-4 h-fit justify-self-end"
              :items="[
                [
                  {
                    label: 'Edit',
                    icon: 'i-ph-note-pencil',
                    click: () => editExtra(item),
                  },
                  {
                    label: 'Delete',
                    icon: 'i-ph-trash',
                    click: () => removeItem(item.item.id),
                  },
                ],
              ]"
              :popper="{ placement: 'bottom-end' }"
              :disabled="isCheckingOut || app.state.isLoading"
            >
              <u-button
                square
                icon="i-ph-dots-three-circle-vertical"
                size="lg"
                class="p-0"
                variant="ghost"
                color="white"
                :disabled="isCheckingOut || app.state.isLoading"
              />
            </u-dropdown>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="auth.userCartExtras.size || auth.userCartShowObjects.size"
      :class="{
        'grid': true,
        'gap-1': true,
        'p-2': true,
        'bg-white': true,
        'dark:bg-gray-900': true,
        'border-t': true,
        'border-gray-300 dark:border-gray-700': true,
      }"
    >
      <template v-if="!isBlockingPurchase">
        <div class="grid grid-cols-[1fr_auto] pb-2 pt-1 text-sm">
          <span class="text-gray-500 dark:text-gray-400"> Subtotal </span>
          <span class="justify-self-end text-gray-500 dark:text-gray-400">{{
            entryFeeReadable
          }}</span>
          <span class="text-gray-500 dark:text-gray-400"> Service Fee </span>
          <span class="justify-self-end text-gray-500 dark:text-gray-400">{{
            serviceFeeReadable
          }}</span>
          <span class="mt-2 font-semibold"> Total: </span>
          <strong class="mt-2 justify-self-end font-semibold">{{
            totalFeeReadable
          }}</strong>
        </div>
        <span class="text-sm italic">
          By submitting entries you agree to abide by all event rules.
        </span>
        <u-button
          :disabled="isCheckingOut || app.state.isLoading"
          :loading="isCheckingOut || app.state.isLoading"
          block
          @click="throttledCheckout"
        >
          Checkout
        </u-button>
        <span
          :class="{
            'flex': true,
            'flex-row-reverse': true,
            'text-xs': true,
            'items-center': true,
            'text-gray-500': true,
            'dark:text-gray-400': true,
          }"
        >
          Checkout will proceed to Stripe.
          <u-icon
            name="i-ph-info"
            class="mr-1"
          />
        </span>
      </template>
      <template v-else>
        <app-banner>
          <p>
            One or more of your entries are blocking checkout. Please verify
            your information is correct and that your requested entries or
            extras do not exceed availability.
          </p>
        </app-banner>
      </template>
      <u-button
        color="white"
        block
        class="mt-4 sm:block md:hidden"
        @click="app.layout.isSlideoverCartOpen = false"
      >
        Close
      </u-button>
    </div>
  </div>
  <modal-edit-entry
    v-if="activeObject"
    v-model="isEntryModalOpen"
    :subuser-id="activeObject.item.subuser_id"
    :event="activeObject"
    :show="activeObject.show"
    :cart-entry="activeObject.item"
    :loading="app.state.isLoading"
    @add-to-cart="updateObject"
  />
  <modal-edit-extra
    v-else-if="activeExtra"
    v-model="isExtraModalOpen"
    :subuser-id="activeExtra.item.subuser_id"
    :event="activeExtra"
    :extra="activeExtra.extra"
    :stock="Math.min(50, activeExtra.quantity_remaining ?? 0)"
    :cart-extra="activeExtra.item"
    :loading="app.state.isLoading"
    @add-to-cart="updateExtra"
  />
</template>

<script lang="ts" setup>
import type Stripe from 'stripe'

const supabase = useSupabaseClient<Supabase>()
const app = useApp()
const auth = useAuth()
const toast = useToast()
const entryFee = computed(() => {
  let totalFee = 0

  for (const entry of auth.userCartShowObjects.values()) {
    totalFee += entry.show.computed_price
  }

  for (const extra of auth.userCartExtras.values()) {
    totalFee += extra.extra.computed_price * extra.item.quantity_requested
  }

  return Math.max(0, totalFee)
})
const entryFeeReadable = computed(() => useMoneyFormat(entryFee.value))
const serviceFee = computed(() => {
  if (entryFee.value <= 0) {
    return 0
  }

  const feePercentage = 0.049
  const flatFee = 0.3
  const unrounded
    = (flatFee + entryFee.value) / (1 - feePercentage) - entryFee.value
  return Math.max(0, usePrecision(unrounded, 2, { math: 'round' }).value)
})
const serviceFeeReadable = computed(() => useMoneyFormat(serviceFee.value))
const totalFee = computed(() => entryFee.value + serviceFee.value)
const totalFeeReadable = computed(() => useMoneyFormat(totalFee.value))
const totalQuantityRequested = computed(() => {
  const quantities: Record<string, number> = {}
  auth.userCart.forEach((item) => {
    if (item.type === 'extra') {
      const extraId = item.id.split('.')[0]
      if (!quantities[extraId]) {
        quantities[extraId] = 0
      }
      quantities[extraId] += item.quantity_requested
    }
  })

  return quantities
})
const isBlockingPurchase = computed(
  () =>
    Array.from(auth.userCartShowObjects.values()).some(
      (item) =>
        item.is_entries_per_specie_full
        || item.is_entries_per_specie_per_exhibitor_full
        || item.show.is_entries_full
        || item.show.is_entries_per_exhibitor_full
        || app.data.time.isBefore(item.registration_start_at)
        || app.data.time.isAfter(item.registration_end_at)
        || item.show.metadata.is_draft,
    )
    || Array.from(auth.userCartExtras.values()).some(
      (item) =>
        item.quantity_remaining <= 0
        || app.data.time.isAfter(item.extra.not_available_at)
        || totalQuantityRequested.value[item.item.id.split('.')[0]]
        > item.quantity_remaining,
    ),
)
const activeObject = ref<ObjectInCart | null>(null)
const activeExtra = ref<ExtraInCart | null>(null)
const isEntryModalOpen = ref(false)
const isExtraModalOpen = ref(false)
const isCheckingOut = ref(false)
const throttledCheckout = useThrottleFn(checkout, 1000)

function editObject(item: ObjectInCart) {
  activeExtra.value = null
  activeObject.value = item

  isExtraModalOpen.value = false
  isEntryModalOpen.value = true
}

function editExtra(item: ExtraInCart) {
  activeObject.value = null
  activeExtra.value = item

  isEntryModalOpen.value = false
  isExtraModalOpen.value = true
}

async function updateObject(params: {
  exhibitor: PortalExhibitor
  old: PortalCartShowObject
  new: PortalCartShowObject
}) {
  try {
    app.state.isLoading = true

    if (!auth.user) {
      throw new Error('User not found')
    }

    const { error } = await supabase
      .from('users')
      .update({
        metadata: {
          ...auth.user.metadata,
          cart: auth.userCart.map((item) => {
            if (item.id === params.old.id) {
              return {
                ...params.new,
                id: params.old.id,
              }
            }

            if (
              item.type === 'show_object'
              && item.event_id === params.new.event_id
              && item.subuser_id === params.new.subuser_id
            ) {
              return {
                ...item,
                exhibitor_info_submitted: {
                  ...item.exhibitor_info_submitted,
                  ...params.new.exhibitor_info_submitted,
                },
              }
            }
            else {
              return item
            }
          }),
        } as SupabaseJson,
      })
      .eq('id', auth.userId)

    if (error) {
      throw error
    }

    activeObject.value = null

    toast.add({ color: 'green', title: 'Item updated.' })
  }
  catch (error) {
    console.error(error)

    toast.add({ color: 'red', title: 'Could not update cart item.' })
  }
  finally {
    app.state.isLoading = false
  }
}

async function removeItem(id: string) {
  try {
    app.state.isLoading = true

    if (!auth.user) {
      throw new Error('User not found')
    }

    const newCart = auth.userCart.filter((item) => item.id !== id)

    if (auth.userCart.length === newCart.length) {
      return
    }

    const { error } = await supabase
      .from('users')
      .update({
        metadata: {
          ...auth.user.metadata,
          cart: newCart,
        } as SupabaseJson,
      })
      .eq('id', auth.userId)

    if (error) {
      throw error
    }

    if (!newCart.length) {
      app.layout.isSlideoverCartOpen = false
    }

    toast.add({
      color: 'green',
      title: 'Item removed from cart!',
    })
  }
  catch (error) {
    console.error(error)

    toast.add({ color: 'red', title: 'Could not remove item from cart.' })
  }
  finally {
    app.state.isLoading = false
  }
}

async function updateExtra(params: {
  old: PortalCartExtra
  new: PortalCartExtra
}) {
  try {
    app.state.isLoading = true

    if (!auth.user) {
      throw new Error('User not found')
    }

    await supabase
      .from('users')
      .update({
        metadata: {
          ...auth.user.metadata,
          cart: auth.userCart.map((item) => {
            if (item.id === params.old.id) {
              return {
                ...params.new,
                id: params.old.id,
              }
            }

            return item
          }),
        } as SupabaseJson,
      })
      .eq('id', auth.userId)

    activeExtra.value = null

    toast.add({ color: 'green', title: 'Item updated.' })
  }
  catch (error) {
    console.error(error)

    toast.add({ color: 'red', title: 'Could not update cart item.' })
  }
  finally {
    app.state.isLoading = false
  }
}

async function checkout() {
  try {
    app.state.isLoading = true
    isCheckingOut.value = true

    if (!auth.user) {
      throw new Error('User not found')
    }

    if (isBlockingPurchase.value) {
      throw new Error('One or more entries are blocking checkout.')
    }

    const trackingId = useV4()

    localStorage.setItem('stripe-checkout-tracking-id', trackingId)

    const { error } = await supabase
      .from('users')
      .update({
        metadata: {
          ...auth.user.metadata,
          cart: auth.userCart.map((item) => {
            if (item.type === 'show_object') {
              return {
                ...item,
                basis:
                  Array.from(auth.userCartShowObjects.values()).find(
                    (e) => e.item.id === item.id,
                  )?.show.computed_price ?? 0,
                tracking_id: trackingId,
              }
            }
            else if (item.type === 'extra') {
              return {
                ...item,
                basis:
                  Array.from(auth.userCartExtras.values()).find(
                    (e) => e.item.id === item.id,
                  )?.extra.computed_price ?? 0,
                tracking_id: trackingId,
              }
            }
          }),
        } as SupabaseJson,
      })
      .eq('id', auth.userId)

    if (error) {
      throw error
    }

    const lineItems: {
      name: string
      amount: number
      quantity: number
      metadata?: Record<string, string | number | null>
    }[] = []
    const totalFeeByStripeConnectId: Record<string, number> = {}
    const totalFeeByEvents: Record<string, number> = {}
    const totalFeeByShows: Record<string, number> = {}
    const totalFeeByCategory: Record<string, number> = {}

    for (const [, item] of auth.userCartShowObjects) {
      lineItems.push({
        name:
          `${item.name} ${item.show.name} Entry `
          + `for ${item.subuser.name_first} ${item.subuser.name_last}`,
        amount: item.show.computed_price,
        quantity: 1,
      })

      if (typeof item.show.metadata.stripe_connect_id === 'string') {
        const key = `${item.id}.${item.show.metadata.stripe_connect_id}`

        if (totalFeeByStripeConnectId[key]) {
          totalFeeByStripeConnectId[key] += item.show.computed_price
        }
        else {
          totalFeeByStripeConnectId[key] = item.show.computed_price
        }
      }

      if (totalFeeByEvents[item.id]) {
        totalFeeByEvents[item.id] += item.show.computed_price
      }
      else {
        totalFeeByEvents[item.id] = item.show.computed_price
      }

      if (totalFeeByShows[item.show.id]) {
        totalFeeByShows[item.show.id] += item.show.computed_price
      }
      else {
        totalFeeByShows[item.show.id] = item.show.computed_price
      }

      if (totalFeeByCategory.show_objects) {
        totalFeeByCategory.show_objects += item.show.computed_price
      }
      else {
        totalFeeByCategory.show_objects = item.show.computed_price
      }
    }

    for (const [, item] of auth.userCartExtras) {
      lineItems.push({
        name:
          `${item.name} ${item.extra.name} for `
          + `${item.subuser.name_first} ${item.subuser.name_last}`,
        amount: item.extra.computed_price,
        quantity: item.item.quantity_requested,
        metadata: {
          variants: JSON.stringify(item.item.variants_requested),
        },
      })

      if (typeof item.extra.metadata.stripe_connect_id === 'string') {
        const key = `${item.id}.${item.extra.metadata.stripe_connect_id}`

        if (totalFeeByStripeConnectId[key]) {
          totalFeeByStripeConnectId[key]
            += item.extra.computed_price * item.item.quantity_requested
        }
        else {
          totalFeeByStripeConnectId[key]
            = item.extra.computed_price * item.item.quantity_requested
        }
      }

      if (totalFeeByEvents[item.id]) {
        totalFeeByEvents[item.id]
          += item.extra.computed_price * item.item.quantity_requested
      }
      else {
        totalFeeByEvents[item.id]
          = item.extra.computed_price * item.item.quantity_requested
      }
      if (totalFeeByCategory.extras) {
        totalFeeByCategory.extras
          += item.extra.computed_price * item.item.quantity_requested
      }
      else {
        totalFeeByCategory.extras
          = item.extra.computed_price * item.item.quantity_requested
      }
    }

    const stripeCheckout = await $fetch<{
      status_code: number
      message: string
      data: Stripe.Response<Stripe.Checkout.Session>
      error?: undefined
    }>('/api/v1/stripe/checkout', {
      method: 'POST',
      body: {
        userId: auth.userId,
        email: auth.user.email,
        cancel_url: `${window.location.origin}/cancel`,
        success_url: `${window.location.origin}/success`,
        lineItems,
        transfers: totalFeeByStripeConnectId,
        amountsByEvents: totalFeeByEvents,
        amountsByShows: totalFeeByShows,
        amountsByCategory: totalFeeByCategory,
        service_fee: serviceFee.value,
        tracking_id: trackingId,
      },
    })

    if (stripeCheckout.status_code !== 200) {
      throw new Error('Could not create checkout session.')
    }

    await navigateTo(stripeCheckout.data.url ?? '/', {
      external: typeof stripeCheckout.data.url === 'string',
    })
  }
  catch (error) {
    isCheckingOut.value = false

    console.error(error)

    localStorage.removeItem('stripe-checkout-tracking-id')

    toast.add({
      color: 'red',
      title: 'Could not create checkout session.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}
</script>
